<template>
  <div>
    <SectionTitle>{{ $t('items.specifications') }}</SectionTitle>
    <table class="table table--striped m-b-4">
      <tbody>
        <tr>
          <td class="nowrap">
            <b>{{ $t('items.articleNumber') }}</b>
          </td>
          <td>{{ item.externalid }}</td>
        </tr>
        <tr
          v-for="attr in itemAttributes"
          :key="attr.key"
        >
          <td class="nowrap topaligned">
            <b>{{ attr.key }}</b>
          </td>
          <td v-if="((typeof attr.value === 'string') && (attr.value.indexOf('.pdf')> 0))">
            <a :href="attr.value">Download</a>
          </td>
          <td v-else-if="(Array.isArray(attr.value))">
            <ul class="m-b-0">
              <li
                v-for="val in attr.value"
                :key="val"
                v-html="val"
              />
            </ul>
          </td>
          <td
            v-else
            v-html="attr.value"
          />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style lang="scss" scoped>
.topaligned {
  vertical-align: top;
}
</style>

<script>
import { mapState } from 'vuex';
import SectionTitle from '@/elements/SectionTitle.vue';

export default {
  components: {
    SectionTitle,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapState({
      attributeTranslations: (state) => state.language.attributeTranslations,
    }),
    itemAttributes() {
      const itemAttributes = [];
      // const { attributeTranslations } = this;
      Object.keys(this.item.attributes).forEach((x) => {
        if ((typeof this.item.attributes[x] === 'string') || (Array.isArray(this.item.attributes[x]))) {
          if (
            ![
              'detail_description',
              'platform_description',
              'category',
              'description',
              'design_file',
              'url_frame_magazine',
              'frame_passport',
              'technology_guide',
              'assembly_manual',
              'safetynotice',
              'ownermanual',
              'customizerurl',
              'collection_year',
              'family',
              'excel_frame_geometry',
              'sharepoint_drive_id',
              'sharepoint_folder_path',
              'outlet_description',
              'video1',
              'video2',
              'video3',
              'video4',
              'video5',
              'search_tags',
              'confidential_until',
              'modelcode',
              'technology',
              'frame_family',
              'seo_description',
              'accessory_mod',
              'groups',
              'google_product_category',
              'sitecolor',
              'geo_type',
              'outlet',
              'voucherlink',
              'vouchertext',
              'vouchercountry',
              'voucherlabel',
              'collections',
            ].includes(x)
          ) {
            itemAttributes.push({
              attrName: x,
              key: this.attributeTranslations[x] ? this.attributeTranslations[x] : x,
              value: this.item.attributes[x],
            });
          }
        }
      });
      const itemAttributesSortOrder = [
        'item_brand',
        'model',
        'color',
        'paint_des',
        'frame_size',
        'helmet_size',
        'range',
        'frame',
        'fork',
        'material',
        'groupset',
        'crank',
        'crankset',
        'crank_arm',
        'crank_chainring',
        'cassette',
        'electric',
        'drive_unit',
        'battery',
        'item_brake_type',
        'front_brake',
        'rear_brake',
        'front_derailleur',
        'rear_derailleur',
        'handlebar',
        'handlebar_width',
        'cockpit_range',
        'stem',
        'seatpost',
        'seatpost_dia',
        'sad_range',
        'saddle',
        'wheelset',
        'front_wheel',
        'rear_wheel',
        'wheel_dia',
        'outer_tyre',
        'max_tire_clearance_700c',
        'configurable',
        'paint_bac',
        'paint_c2',
        'paint_c3',
        'paint_c4',
        'paint_c5',
        'paint_c6',
        'paint_finish',
        'sex',
        'weight_limit',
        'compatible_frameplatforms',
      ];

      return itemAttributes.sort((a, b) => {
        let indA = itemAttributesSortOrder.indexOf(a.attrName);
        let indB = itemAttributesSortOrder.indexOf(b.attrName);

        if (indA === -1) {
          if (indA === -1) { indA = itemAttributesSortOrder.length - 1; }
        }
        if (indB === -1) { indB = itemAttributesSortOrder.length - 1; }

        if (indA < indB) {
          return -1;
        } if (indA > indB) {
          return 1;
        }
        return 0;
      });
    },
  },
};
</script>
