import { apiInstance } from './api';

export const subscribe = async (subsription) => {
  await apiInstance.post('/v1/notifications/push/subscribe', subsription);
};

export const checkSubscription = async (subsription) => {
  await apiInstance.post('/v1/notifications/push/checksubscription', subsription);
};

export const unSubscribe = async (subsription) => {
  await apiInstance.post('/v1/notifications/push/unsubscribe', subsription);
};

export const enablePush = async () => {
  await apiInstance.post('/v1/notifications/push/enable');
};

export const disablePush = async () => {
  await apiInstance.post('/v1/notifications/push/disable');
};

export const test = async (message, url) => {
  await apiInstance.post('/v1/notifications/send', { message, url });
};

export const getNotifications = async () => {
  const result = await apiInstance.get(`/v1/notifications?cid=${window.store.state.customer.selectedCustomerId}&lang=${window.store.state.language.current}`);
  return result.data.result;
};

export const getNotificationsPreferences = async () => {
  const result = await apiInstance.get(`/v1/notifications/preferences?cid=${window.store.state.customer.selectedCustomerId}&lang=${window.store.state.language.current}`);
  return result.data.result;
};

export const enablePreference = async (type) => {
  await apiInstance.post('/v1/notifications/preferences/enable', { cid: window.store.state.customer.selectedCustomerId, type });
};

export const disablePreference = async (type) => {
  await apiInstance.post('/v1/notifications/preferences/disable', { cid: window.store.state.customer.selectedCustomerId, type });
};

export const track = async (path) => {
  const result = await apiInstance.post('/v1/track', { path });
  if (result.data && result.data.result && result.data.result.count) {
    window.store.dispatch('notifications/setCount', result.data.result.count);
  }
};
