import { render, staticRenderFns } from "./customizer.vue?vue&type=template&id=07c6e1e2&scoped=true"
import script from "./customizer.vue?vue&type=script&lang=js"
export * from "./customizer.vue?vue&type=script&lang=js"
import style1 from "./customizer.vue?vue&type=style&index=1&id=07c6e1e2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07c6e1e2",
  null
  
)

export default component.exports