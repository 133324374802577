<template>
  <div>
    <ItemsNav />
    <router-view />
    <div id="placeholder-dashboard-top" />
    <router-link
      v-if="locationWithoutOpeninghours.length"
      :to="locationWithoutOpeninghours.length>1
        ? {name: 'accountLocations'}
        : {name: 'accountLocationsView', params: {id: locationWithoutOpeninghours[0].externalid}}
      "
      class="container banner__notification"
    >
      <small class="banner__content m-l-1">
        {{
          locationWithoutOpeninghours.length>1
            ? $t('header.banners.multipleLocationsWithoutOpeninghours')
            : $t('header.banners.locationWithoutOpeninghours', {location: locationWithoutOpeninghours[0].Name})
        }}
      </small>
    </router-link>
    <header class="main__title">
      <div class="container">
        <div
          v-if="true==false"
        >
          <div class="banner__promo">
            <small class="m-l-1">{{ $t('header.info') }}</small><!--<div><small> ({{ $t('items.promoBanner') }})</small></div>-->
          </div>
        </div>
        <!--<div class="row">
          <div class="col main__title__inner">
            <h1>{{ $t('header.dashboard') }}</h1>
          </div>
        </div>-->
      </div>
    </header>

    <div class="container m-t-4">
      <div class="row">
        <!-- Quicklinks -->
        <div class="col-lg-12 m-b-8 m-md-b-4">
          <h2>Quicklinks</h2>

          <div class="quicklinks">
            <router-link
              :to="{name: 'bikeItems'}"
              class="quicklinks__item"
            >
              <div class="quicklinks__image">
                <img src="../assets/img/icons/icon-bike.svg">
              </div>
              {{ $t('items.cat.allBikes') }}
            </router-link>
            <router-link
              v-if="ENABLE_BUILD_YOUR_OWN"
              :to="{name: 'buildYourOwnModels'}"
              class="quicklinks__item"
            >
              <div class="quicklinks__image">
                <img src="../assets/img/icons/icon-builder.svg">
              </div>
              {{ $t('items.cat.buildYourOwn') }}
            </router-link>
            <router-link
              :to="{name: 'myOrders'}"
              class="quicklinks__item"
            >
              <div class="quicklinks__image">
                <img src="../assets/img/icons/icon-orders.svg">
              </div>
              {{ $t('account.account.myOrders.title') }}
            </router-link>
            <router-link
              :to="{name: 'myStock'}"
              class="quicklinks__item"
            >
              <div
                v-b-tooltip.hover.top
                class="positionLogo"
                :title="$t('account.about.socialFeed.share')"
              >
                <a
                  target="_blank"
                  class="test"
                  :href="`https://www.facebook.com/sharer/sharer.php?u=${baseB2cUrl}/${getCurrentLocal}/inventory?cus=${selectedCustomer.externalid}`"
                  @click.prevent="openFacebookShare(`https://www.facebook.com/sharer/sharer.php?u=${baseB2cUrl}/${getCurrentLocal}/inventory?cus=${selectedCustomer.externalid}`)"
                >
                  <img
                    :src="require('@/assets/img/icons/Facebook_logo.svg')"
                    width="20"
                    height="20"
                    class=""
                    alt="Logo"
                  >
                </a>
              </div>
              <div class="quicklinks__image">
                <i
                  class="uil uil-truck-loading"
                />
              </div>
              <div
                v-if="myStockValue && myStockValue.outdated && myStockValue.outdated.length"
                class="quicklinks__badge"
              >
                {{ myStockValue.outdated.length }}
              </div>
              <div
                v-else-if="myStockValue && myStockValue.items && myStockValue.items.length === 0"
                class="quicklinks__badge"
              >
                {{ $t('account.account.myStock.new') }}
              </div>
              {{ $t('account.account.myStock.title') }}
            </router-link>
            <router-link
              v-if="showMyStats"
              :to="{name: 'myStats'}"
              class="quicklinks__item"
            >
              <div
                class="quicklinks__stats"
              >
                <loader v-if="myStatsLoading" />
                <template v-if="typeof(myStatsValue)==='number'">
                  <div class="quicklinks__stats__value">
                    {{ myStatsValue }}
                  </div>
                  <div class="quicklinks__stats__description">
                    {{ $t('account.account.myStats.tile.description') }}
                  </div>
                </template>
              </div>
              {{ $t('account.account.myStats.title') }}
            </router-link>

            <!--<router-link
              :to="{name: 'notifications'}"
              class="quicklinks__item"
            >
              <div class="quicklinks__image">
                <img src="../assets/img/icons/navbar-notifications.svg">
              </div>
              {{ $t("header.notifications") }}
            </router-link>-->
          </div>
        </div>

        <!-- Quickstats
        <div class="col-lg-5 offset-lg-1 m-b-8 m-md-b-4">
          <h2>{{ $t('account.account.myStats.title') }}</h2>

          <EmptyState icon="chart-line">
            {{ $t('general.availableSoon') }}
          </EmptyState>

          <router-link to class="more-link">
            <i class="uil uil-chart-line"></i>
            Meer statistieken
          </router-link>
        </div>-->
      </div>
    </div>

    <div class="container m-b-8 m-md-b-4">
      <div class="row m-b-4">
        <div class="col-md-6 col-12">
          <h2 class="m-b-0">
            {{ $t('account.about.newsAndUpdates.title') }}
          </h2>
        </div>
        <div class="col-md-6 col-12 m-sm-t-1 text-right text-sm-left">
          <span v-if="!errStatusMedia">
            <router-link
              :to="'/account/newsAndUpdates'"
              class="more-link"
            >
              {{ $t('account.about.newsAndUpdates.morenews') }}
            </router-link>
          </span>
        </div>
      </div>
      <div class="row">
        <div class="cards-to-scroll">
          <div
            v-if="errStatusMedia"
            class="col"
          >
            <Message
              :type="objMessage.type"
              :icon="false"
              :content="objMessage.content"
              :show-close="true"
              @closeMessage="closeMessageMedia"
            >
              <strong>{{ $t(`api.${objMessage.title}`) }}</strong>
            </Message>
          </div>
          <div
            v-else
            class="cards-to-scroll__inner"
          >
            <div
              v-for="(feed, index) in newsAndMediaFeeds"
              :key="index"
              class="card-promo"
            >
              <MediaTeaser
                :id="feed.id"
                :url="`/dashboard/news/${feed.id}`"
                :date="feed.date_online"
                :title="feed.title"
                :summary="feed.summary_text"
                :image="feed.images && feed.images.image && feed.images.image.thumb?feed.images.image.thumb:placeholder"
                :logo="require('@/assets/img/icons/Facebook_logo.svg')"
                :social="feed.social"
                :type="feed.type"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container m-b-8 m-md-b-4">
      <div class="row m-b-4">
        <div class="col-md-6 col-12">
          <h2 class="m-b-0">
            {{ $t('account.about.socialFeed.title') }}
          </h2>
        </div>
        <div class="col-md-6 col-12 m-sm-t-1 text-right text-sm-left">
          <span
            v-if="!errStatusSocial"
          >
            <router-link
              :to="'/account/socialFeeds'"
              class="more-link"
            >
              {{ $t('account.about.socialFeed.moresocialfeeds') }}
            </router-link>
          </span>
        </div>
      </div>
      <div class="row">
        <div class="cards-to-scroll">
          <div
            v-if="errStatusSocial"
            class="col"
          >
            <Message
              :type="objMessage.type"
              :icon="false"
              :content="objMessage.content"
              :show-close="true"
              @closeMessage="closeMessageSocial"
            >
              <strong>{{ $t(`api.${objMessage.title}`) }}</strong>
            </Message>
          </div>
          <div
            v-else-if="socialMediaFeedsLoaded"
            class="cards-to-scroll__inner"
          >
            <div
              v-for="(feed, index) in socialFeeds"
              :key="index"
              class="card-promo"
            >
              <MediaTeaser
                :url="`/dashboard/socialFeeds/${feed.id}`"
                :date="feed.created_time"
                :title="feed.from.name"
                :summary="feed.message"
                :image="feed.full_picture?feed.full_picture:placeholder"
                :logo="require('@/assets/img/icons/Facebook_logo.svg')"
                :permalink="feed.permalink_url"
                :type="feed.type"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import EmptyState from '@/elements/EmptyState.vue';
import ItemsNav from '@/components/ItemsNav.vue';
import Message from '@/components/Message.vue';
import { getMediafeed, getDealerStats, GetStock } from '@/api/api';
import MediaTeaser from '@/components/MediaTeaser.vue';
import { getFacebookFeeds } from '@/api/socialmedia';
import placeholder from '@/assets/img/product_placeholder.jpg';
import loader from '@/elements/loader.vue';
import { NoResponseApiError } from '../api/apiErrors';

export default {
  components: {
    // EmptyState,
    ItemsNav,
    Message,
    MediaTeaser,
    loader,
  },
  data() {
    return {
      baseB2cUrl: process.env.VUE_APP_B2C_URL,
      newsAndMediaFeeds: [],
      socialFeeds: [],
      errStatusSocial: false,
      errStatusMedia: false,
      objMessage: {},
      socialMediaFeedsLoaded: false,
      placeholder,
      myStatsValue: undefined,
      myStatsLoading: false,
      myStockValue: undefined,
      myStockLoading: false,
    };
  },
  computed: {
    ...mapState({
      selectedCustomer: (state) => state.customer.selectedCustomer,
      ENABLE_BUILD_YOUR_OWN: (state) => [4].includes(state.auth.user.type) || process.env.VUE_APP_ENABLE_BUILD_YOUR_OWN === 'true',
      isPreviewMode: (state) => state.global.isPreviewMode,
      user: (state) => state.auth.user,
      locationWithoutOpeninghours: (state) => state.customer.selectedCustomer.addresses.filter((addr) => {
        const visibleB2C = addr.B2CRidleydealerlocator;
        const hasOpeningshours = Array.from(Object.entries(addr.openinghours || {})).find(
          ([, timewindows]) => timewindows && timewindows.find((tw) => tw.from || tw.to),
        );
        return visibleB2C && !hasOpeningshours;
      }),
    }),
    getCurrentLocal() {
      return this.$store.state.language.currentApi === 'default' ? 'en_INT' : this.$store.state.language.currentApi;
    },
    showMyStats() {
      return true; // Fully enabled for customers now
    },
  },
  metaInfo: {
    title: 'B2B - Belgian Cycling Factory',
    titleTemplate: null,
  },
  async mounted() {
    if (this.showMyStats) this.loadMyStats();// async, but no need to wait on it
    this.loadMyStockIndicator();// async, but no need to wait on it
    try {
      // Start - loading media feeds(promo & news) from api
      const result = await getMediafeed(
        this.selectedCustomer.id,
        this.$store.state.language.currentApi,
      );
      const arrNewsAndMediaFeeds = result.data.result;
      // check if we have mandatory read message
      const newsfeed = arrNewsAndMediaFeeds.find((newsfd) => newsfd.mandatory_read && !newsfd.readbyuser);
      // console.log('`Newsfeed mount ${newsfeed.id}`');
      if (newsfeed) {
        this.$router.push(`/dashboard/news/${newsfeed.id}`);
      }
      if (arrNewsAndMediaFeeds.length > 6) {
        this.newsAndMediaFeeds = arrNewsAndMediaFeeds.slice(0, 6);
      } else {
        this.newsAndMediaFeeds = arrNewsAndMediaFeeds;
      }
      console.log(this.selectedCustomer);
    } catch (error) {
      if (error instanceof NoResponseApiError) {
        this.errStatusMedia = true;
        this.objMessage = {
          type: 'error',
          title: 'apiNoResponseTitle',
          content: error.apiMessage,
        };
      } else {
        this.errStatusMedia = true;
        this.objMessage = {
          type: 'error',
          title: 'UnexpectedError',
          content: error.apiMessage,
        };
      }
    }
    try {
      // Start - loading social media feeds from api
      const tempArr = await getFacebookFeeds();
      this.socialFeeds = (tempArr.data.length > 6) ? tempArr.data.slice(0, 6) : tempArr.data;
      this.socialMediaFeedsLoaded = true;
      // End - loading social media feeds from api
    } catch (error) {
      if (error instanceof NoResponseApiError) {
        this.errStatusSocial = true;
        this.objMessage = {
          type: 'error',
          title: 'apiNoResponseTitle',
          content: error.apiMessage,
        };
      } else {
        this.errStatusSocial = true;
        this.objMessage = {
          type: 'error',
          title: 'UnexpectedError',
          content: error.apiMessage,
        };
      }
    }
  },
  methods: {
    openFacebookShare(url) {
      window.open(url, '_blank');
    },
    closeMessageSocial() {
      this.errStatusSocial = false;
    },
    closeMessageMedia() {
      this.errStatusMedia = false;
    },
    async loadMyStats() {
      if (!this.selectedCustomer || !this.selectedCustomer.addresses || !this.selectedCustomer.addresses[0]) return;
      this.myStatsLoading = true;
      try {
        const location = this.selectedCustomer.addresses[0];
        const n = new Date();
        const t = new Date(n.getFullYear(), n.getMonth(), n.getDate());
        const last31d = [new Date(t.getFullYear(), t.getMonth(), t.getDate() - 31), t];
        const stats = await getDealerStats({
          customerId: this.selectedCustomer.id,
          locationId: location.externalid,
          type: 'frameplatforms',
          distance: 50,
          range: last31d,
          granularity: 'century', // Doesn't matter, we'll add these up anyway
        });
        const total = stats.map((c) => c.data.map((x) => x.count).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0);
        this.myStatsValue = total;
      } catch (err) {
        console.error(err);
      }
      this.myStatsLoading = false;
    },
    async loadMyStockIndicator() {
      if (!this.selectedCustomer) return;
      this.myStockLoading = true;
      try {
        const stock = await GetStock(this.selectedCustomer.id);

        this.myStockValue = {
          items: stock,
          outdated: stock.filter((x) => x.outdated),
        };
      } catch (err) {
        console.error(err);
      }
      this.myStockLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
@media (min-width: 992px) {
  #placeholder-dashboard-top {
    margin-top: -4rem;
  }
  .secundary-links {
    #placeholder-quicklinks {
      font-size: 2.5rem;
      margin-bottom: 4rem;
      height: 1.22em;
    }

    .quicklinks {
      justify-content: flex-end;

      .quicklinks__item {
        flex: 0 1 30rem;
      }
    }
  }
}
.positionLogo {
    position: absolute;
      left: 0px;
      top: 0px;
      padding: 0.5rem 0.5rem;
}
.positionShare {
  position: absolute;
  right: 5px;
  top:4px;
}
.logo {
  margin: 5px;
}

.share {
  background-color: #1b76f3;
  color: white;
}
.test {
  cursor: pointer;
}
</style>
