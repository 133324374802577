<template>
  <div class="container">
    <div
      id="planyo_content"
      class="planyo"
    >
      <loader />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
function get_param(name) { name = name.replace(/[\[]/, '\\\[').replace(/[\]]/, '\\\]'); const regexS = `[\\?&]${name}=([^&#]*)`; const regex = new RegExp(regexS); const results = regex.exec(window.location.href); if (results == null) return null; return results[1]; }
if (get_param('mode')) planyo_embed_mode = get_param('mode');
function get_full_planyo_file_path(name) { if (planyo_files_location.length === 0 || planyo_files_location.lastIndexOf('/') === planyo_files_location.length - 1) return planyo_files_location + name; return `${planyo_files_location}/${name}`; }

import loader from '@/elements/loader.vue';

export default {
  name: 'Planyo',
  metaInfo() {
    return {
      title: this.$t('header.notifications'),
    };
  },
  components: {
    loader
  },
  created() {
    const plugin = document.createElement("script");
    plugin.setAttribute(
      "src",
      "https://www.planyo.com/Plugins/PlanyoFiles/jquery.min.js"
    );
    plugin.async = true;
    document.head.appendChild(plugin);

    const plugin2 = document.createElement("script");
    plugin2.setAttribute(
      "src",
      "https://www.planyo.com/Plugins/PlanyoFiles/booking-utils.js"
    );
    plugin2.async = true;
    document.head.appendChild(plugin2);

    window.planyo_dont_use_drupal_plugin = true;
    window.planyo_site_id = '49479'; // ID of your planyo site
    window.planyo_default_mode = 'search'; // one of: 'resource_list' (displays list of resources with photos, descriptions etc.), 'search' (displays the search box), 'empty' (will not display anything by default but will require you to either pass the resource ID as parameter in the URL (resource_id) or add an external search box or calendar preview), 'upcoming_availability' (displays a quick list of all upcoming availability)
    window.extra_search_fields = 'prop_res_brand,prop_res_segment,prop_res_model_type,prop_res_size'; // comma-separated extra fields in the search box, e.g. 'Number of persons'. You first need to define them in settings/custom resource properties
    window.sort_fields = ''; // comma-separated sort fields for the search box -- a single field will hide the sort dropdown box
    window.planyo_resource_ordering = 'name'; // optional sort criterium for resource list
    window.planyo_include_js_library = true; // set this to true if jQuery (required) should be included by this plugin, or false if your website already includes jQuery
    window.planyo_attribs = '?range_search=1&ppp_horizontal_searchbox=1'; // optionally you can insert the attribute string here
    window.planyo_resource_id = ''; // optional: ID of the resource being reserved
    window.planyo_language = 'EN'; // you can optionally change the language here, e.g. 'FR' or 'ES' or pass the languge in the 'lang' parameter. 'AUTO' means the language is detected automatically
    window.ulap_script = 'jsonp'; // leave this as "jsonp" for a plain-javascript implementation --OR-- if using a php/asp.net/java implementation, one of the ULAP scripts: "ulap.php", "ulap.aspx", "ulap.jsp", in such case you must download the advanced integration Planyo files from http://www.planyo.com/Plugins/PlanyoFiles/planyo-files.zip
    window.planyo_use_https = true; // set this to true if embedding planyo on a secure website (SSL)
    window.planyo_files_location = `${planyo_use_https ? 'https' : 'http'}://www.planyo.com/Plugins/PlanyoFiles`; // relative or absolute directory where the planyo files are kept (leave unchanged for plain-javascript implementation, otherwise e.g. '/planyo-files' when using the ULAP scripts)
    window.empty_mode = false; // should be always set to false

    window.planyo_login = {first_name: "VAL1", last_name: "VAL2", email: "VAL3", login_cs: "VAL4"};
  }
};
</script>
