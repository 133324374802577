import VueRouter from 'vue-router';
import VueI18n from 'vue-i18n';
import VueFuse from 'vue-fuse';
import VueMeta from 'vue-meta';
import VueLazyLoad from 'vue-lazyload';
import VueGtag from 'vue-gtag';
import * as VueGoogleMaps from 'vue2-google-maps';
import Vuelidate from 'vuelidate';
import VueCarousel from 'vue-carousel';
import SocialSharing from 'vue-social-sharing';
import VueYouTubeEmbed from 'vue-youtube-embed';
import {
  PopoverPlugin, DropdownPlugin, CardPlugin, TooltipPlugin, InputGroupPlugin,
  ButtonPlugin, FormInputPlugin, FormGroupPlugin,
} from 'bootstrap-vue';
import VueHtmlToPaper from 'vue-html-to-paper';
import Vue from './vue';

import App from './App.vue';
import store from './store';
import { afterEach, routes } from './routes';

import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/scss/screen.scss';

import translations from './translations';
import './registerServiceWorker';

window.Vue = Vue;

Vue.config.productionTip = false;

Vue.use(SocialSharing);
Vue.use(VueYouTubeEmbed);
Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.use(VueFuse);
Vue.use(VueMeta);
Vue.use(VueLazyLoad);
Vue.use(VueCarousel);
Vue.use(PopoverPlugin);
Vue.use(DropdownPlugin);
Vue.use(InputGroupPlugin);
Vue.use(ButtonPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormGroupPlugin);
Vue.use(CardPlugin);
Vue.use(TooltipPlugin);
Vue.use(
  VueGoogleMaps,
  {
    load: {
      key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      libraries: 'places',
    },
  },
);
Vue.use(Vuelidate);

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes',
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css',
  ],
};

Vue.use(VueHtmlToPaper, options);

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // savedPosition is only available for popstate navigations.
      return savedPosition;
    }

    if (to.hash) {
      return {
        selector: to.hash,
        offset: {
          y: 90,
        },
      };
    } if (to.name === from.name) {
      return null;
    }
    if (!to.meta.keepPosition && !from.meta.keepPosition) {
      return { x: 0, y: 0 };
    }
    return null;
  },
});

router.afterEach(afterEach);
Vue.use(
  VueGtag,
  {
    config: {
      id: process.env.VUE_APP_ANAYLYTICS_ID,
    },
    enabled: process.env.VUE_APP_ANAYLYTICS_ENABLED,
  },
  router,
);

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: translations,
});

window.i18n = i18n;
window.store = store;
window.router = router;
window.Vue = Vue;
window.app = new Vue({
  store,
  i18n,
  router,
  render: (h) => h(App),
}).$mount('#app');
