<template>
  <div
    id="products__nav"
    class="products__nav m-b-4"
  >
    <div class="container">
      <div class="row">
        <div class="col-12 products__nav__holder">
          <router-link
            :to="{name: 'bikeItems'}"
            class="products__nav__item"
            active-class="active"
          >
            <div class="products__nav__image">
              <img src="../assets/img/icons/icon-bike.svg">
            </div>
            {{ $t('items.cat.allBikes') }}
          </router-link>
          <router-link
            v-if="ENABLE_BUILD_YOUR_OWN"
            :to="{name: 'buildYourOwnModels'}"
            class="products__nav__item"
            active-class="active"
          >
            <div class="products__nav__image">
              <img src="../assets/img/icons/icon-builder.svg">
            </div>
            {{ $t('items.cat.buildYourOwn') }}
          </router-link>

          <router-link
            :to="{name: 'accessoryItems'}"
            class="products__nav__item"
            active-class="active"
          >
            <div class="products__nav__image">
              <img src="../assets/img/icons/icon-accessories.svg">
            </div>
            {{ $t('items.cat.accessories') }}
          </router-link>

          <router-link
            :to="{name: 'partItems'}"
            class="products__nav__item"
            active-class="active"
          >
            <div class="products__nav__image">
              <img src="../assets/img/icons/icon-parts.svg">
            </div>
            {{ $t('items.cat.components') }}
          </router-link>

          <router-link
            :to="{name: 'outletItems'}"
            class="products__nav__item"
            active-class="active"
          >
            <div class="products__nav__image">
              <img src="../assets/img/icons/icon-outlet.svg">
            </div>
            {{ $t('items.cat.outlet') }}
          </router-link>

          <router-link
            :to="{name: 'promoItems'}"
            class="products__nav__item"
            active-class="active"
          >
            <div class="products__nav__image">
              <img src="../assets/img/icons/icon-promo.svg">
            </div>
            {{ $t('items.cat.promoMaterial') }}
          </router-link>

          <router-link
            v-if="isPreviewMode"
            to="#"
            class="products__nav__item"
            active-class="active"
          >
            <div class="products__nav__image">
              <img src="../assets/img/icons/icon-question.svg">
            </div>
            {{ $t('items.cat.decisionAid') }}
          </router-link>

          <router-link
            v-if="isPreviewMode"
            to="#"
            class="products__nav__item"
            active-class="active"
          >
            <div class="products__nav__image">
              <img src="../assets/img/icons/icon-action.svg">
            </div>
            {{ $t('items.cat.actions') }}
          </router-link>
          <router-link
            v-if="sales"
            :to="{name: 'prebook', params: { brand: 'ridley' }}"
            class="products__nav__item"
            active-class="active"
          >
            <div class="products__nav__image">
              <img src="../assets/img/icons/preorder.svg">
            </div>
            {{ $t('cart.preOrder') }} Ridley
          </router-link>
          <router-link
            v-if="sales"
            :to="{name: 'prebook', params: { brand: 'merckx' }}"
            class="products__nav__item"
            active-class="active"
          >
            <div class="products__nav__image">
              <img src="../assets/img/icons/preorder.svg">
            </div>
            {{ $t('cart.preOrder') }} EM
          </router-link>
          <router-link
            v-if="sales"
            :to="{name: 'prebook', params: { brand: 'res' }}"
            class="products__nav__item"
            active-class="active"
          >
            <div class="products__nav__image">
              <img src="../assets/img/icons/preorder.svg">
            </div>
            {{ $t('cart.preOrder') }} RES
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    active: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapState({
      isPreviewMode: (state) => state.global.isPreviewMode,
      ENABLE_BUILD_YOUR_OWN: (state) => [4].includes(state.auth.user.type) || process.env.VUE_APP_ENABLE_BUILD_YOUR_OWN === 'true',
    }),
    sales() {
      return [2, 3, 4, 5].includes(this.$store.state.auth.user.type);
    },
  },

};
</script>
