import { weeksBetween, formatDate } from './dateUtils';

export const maxItemsAvailable = (availability) => {
  if (availability) {
    const availableQuantities = availability.filter((o) => o.date).map((o) => o.quantity);
    return availableQuantities.length > 0 ? Math.max(...availableQuantities) : 0;
  }
  return 0;
};

export const getAvailabilityDate = (availabilityAtt, quantity = 1) => {
  if (availabilityAtt) {
    const availableQuantities = availabilityAtt.filter((o) => o.date).map((o) => o.quantity).sort();
    const quantityToCheck = availableQuantities.includes(parseInt(quantity, 10))
      ? parseInt(quantity, 10)
      : availableQuantities.reduce((quantityToReturn, q) => {
        if (!quantityToReturn && q > parseInt(quantity, 10)) {
          return q;
        }
        return quantityToReturn;
      }, null);
    const availability = availabilityAtt.find((o) => parseInt(o.quantity, 10) === quantityToCheck);
    if (!availability) {
      return null;
    }
    return availability.date;
  }
  return null;
};

export const weeksPerQuantity = (availabilityAtt = [], quantity = 1) => {
  if (availabilityAtt) {
    if (availabilityAtt) {
      const availabilityDate = getAvailabilityDate(availabilityAtt, quantity);
      return availabilityDate
        ? weeksBetween(new Date(), new Date(availabilityDate))
        : false;
    }
    return false;
  }
  return false;
};

export const availabilityText = (availabilityAtt = [], quantity, showRetailPrices = false) => {
  if (!showRetailPrices) {
    const date = getAvailabilityDate(availabilityAtt, quantity);
    if (date) {
      return formatDate(getAvailabilityDate(availabilityAtt, quantity));
    }
  }
  const weeks = weeksPerQuantity(availabilityAtt, quantity);
  if (weeks !== false) {
    if (weeks < 1) {
      return window.i18n.t('cart.directlyAvailable');
    } if (weeks < 3) {
      return `${weeks} - ${weeks + 1} ${window.i18n.t('cart.weeks')}`;
    }
    return `${weeks} - ${weeks + 2} ${window.i18n.t('cart.weeks')}`;
  }
  return window.i18n.t('cart.notAvailable');
};
